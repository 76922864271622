import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Heading, Text, Flex } from 'rebass/styled-components';

import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout>
      <Helmet>
        <title>Attention Capital</title>
      </Helmet>
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      css={{ minHeight: '60vh' }}
    >
      <Heading fontSize={6} mb={4}>
        NOT FOUND
      </Heading>
      <Text fontSize={4} textAlign="center">
        You just hit a page that doesn&#39;t exist... the sadness.
      </Text>
    </Flex>
  </Layout>
);

export default NotFoundPage;
